export default {
  formTitle: "Kredit ohne Schufa Prüfung",
  formSubTitle: "Auszahlung in 5 Min.",
  heading: "Sofort-Kredit in 5 Min.",
  subHeading: "100% Kostenlos und Unverbindlich",
  notice: `Der Kredit ohne Schufa wird bei unseren Kunden immer beliebter. Die Vorteile liegen auf der Hand: keine Schufa-Auskunft und keine Schufa-Eintragung. Dank unserer langjährigen Erfahrung und unserer guten Kontakte im Finanzbereich können wir hier schnell und zuverlässig vermitteln.

  Überzeugen Sie sich von unseren Fähigkeiten! Wir vermitteln selbst schwierigste Fälle und passen uns Ihrer individuellen Situation an.`,
  itemsTitle: "Ein Kredit ohne Schufa hat viele Vorteile für Sie:",
  items: [
    "Ihre Bank erlangt keine Kenntnis",
    "Bank- oder Schufa-Auskunft entfallen",
    "Es erfolgt keine Eintragung ins Schufa-Register",
    "Gleichbleibende Raten und fester Zinssatz",
    "Vermittlung auch bei negativen Schufa-Einträgen",
    "Ihre Bonität bleibt gewahrt",
    "Bar-Auszahlung durch Post möglich",
    "Keine zusätzlichen Sicherheiten oder Bürgschaften nötig",
    "Bank- oder Beratertermine entfallen für Sie",
    "Abwicklung auf dem Postweg - sparen Sie sich lästige Gänge zu Beratern oder Banken",
    "Kreditvergabe erfolgt durch renommierte Banken im In- und Ausland",
    "Sparen Sie sich die Formalitäten - wir übernehmen das für Sie",
    "Bestehende Krediten und Schulden beeinträchtigen die Zusage nicht",
    "Zusage erfolgt sofort online!",
  ],

  tncText: "*Kostenlos & Unverbindlich (Wie es der Rechtslage entspricht)",
  btnText: "Jetzt Antrag erstellen*",
  repsTitle: "REPRÄSENTATIVEN BEISPIEL",
  repsExample: `*Angaben gem. § 6a PAngV: <br/><br/>Unveränderlicher Sollzinssatz ab 3,92%, effektiver Jahreszins ab 3,99% - 15,99%, Nettodarlehensbetrag ab 1.000,- bis 50.000,- €, Laufzeit von 12 bis 120 Monaten, Bonität vorausgesetzt. Bei einem Nettodarlehensbetrag von 10.000,- € und einer Laufzeit von 36 Monaten erhalten 2/3 der Kunden von Sofort24Kredit vorraussichttlich einen eff. Zinssatz von 8,90% oder günstiger (geb. Sollzinssatz 5,26%)."`,
}
